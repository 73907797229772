<template>
  <app-layout
    parent="/workers"
    isDetails
    class="worker--details container col-lg-8 col-md-9 col-sm-12"
    :title="canViewName ? fullName : currentWorkerId"
  >
    <template #backButtonText>Workers</template>
    <template v-slot:header>
      <div class="ml-2 mt-1 content__header__more">
        <Chip
          :color="getColorByStatus(currentWorker.status)"
          class="chip--status"
          shrink
        >
          {{
            (currentWorker.status === "invited"
              ? "PENDING ACTIVATION"
              : currentWorker.status) | upperCase
          }}
        </Chip>
        <span class="tag my-auto ml-2 text-mono secondary-text">
          CSA-{{ currentWorkerId }}
        </span>
      </div>
      <DotMenu
        class="header--dot-menu dot-menu"
        :items="workerMenuItems"
        @select="openDeleteWorkerDialog"
        v-if="canDeleteWorker"
      />
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="workerTabs"
        centered
        class="basic-tabs"
        @change="handleTabChange"
      >
        <v-tab v-for="section in sections" :key="section">{{ section }}</v-tab>
      </v-tabs>
      <router-view
        class="py-6"
        v-if="worker"
        @onUpdate="handleUpdate"
        :isLoading="isLoading"
      />
      <!-- Dialogs -->
      <Dialog :data="deleteWorkerDialog">
        <template v-slot:dialog.actions>
          <PrimaryButton @click.native="deleteWorkerDialog.dialog = false" text>
            Cancel
          </PrimaryButton>
          <ErrorButton :loading="isDeleting" @click.native="handleDeleteWorker">
            Deactivate Worker
          </ErrorButton>
        </template>
      </Dialog>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import { DotMenu } from "@/components/common/Menu";
import Dialog from "@/components/common/Dialog";
import {
  DELETE_WORKER,
  GET_WORKER,
  GET_WORKER_APPLICATIONS,
  GET_INTERMEDIARY_COMPANY
} from "@/store/modules/workers/action-types";
import { mapState, mapActions } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { INIT_WORKER_DATA } from "@/constants/workers";
import { getFullName } from "@/utils/users";
import Chip from "@/components/common/Chip";
import { getColorByStatus } from "@/utils/workers";
import { includes } from "lodash";
import { TAB_INDEXES } from "@/constants/workers";

export default {
  components: {
    appLayout,
    DotMenu,
    Dialog,
    PrimaryButton,
    ErrorButton,
    Chip
  },
  created() {
    this.getIntermediaryCompany(this.currentWorkerId);
    this.getWorker(this.currentWorkerId);
    this.workerTabs = this.initialSection;
  },
  computed: {
    ...mapState("workers", {
      currentWorker: state => state.currentWorker,
      isLoading: state => state.isFetchingData,
      fetchingError: state => state.fetchingError
    }),
    ...mapState("auth", {
      permissions: state => state.permissions
    }),
    error() {
      return this.fetchingError;
    },
    isErrorVisible() {
      return !!this.error;
    },
    worker() {
      const { complianceChecks } = INIT_WORKER_DATA;
      return { ...this.currentWorker, complianceChecks };
    },
    workerMenuItems() {
      return [
        {
          id: "worker",
          title: "Deactivate Worker"
        }
      ];
    },
    fullName() {
      return getFullName(this.currentWorker);
    },
    currentWorkerId() {
      return this.$route.params.id;
    },
    canViewName() {
      return this.permissions["workers.view.name"];
    },
    canDeleteWorker() {
      return this.permissions["workers.delete"];
    },
    initialSection() {
      const path = this.$router.currentRoute.path;
      switch (true) {
        case includes(path, "profile"):
          return TAB_INDEXES.PROFILE;
        case includes(path, "activity"):
          return TAB_INDEXES.ACTIVITY;
        default:
          return TAB_INDEXES.PROFILE;
      }
    }
  },
  data() {
    return {
      isDeleting: false,
      workerTabs: null,
      sections: ["profile", "activity"],
      deleteWorkerDialog: {
        dialog: false,
        title: "Are you sure you want to deactivate the worker’s profile?"
      }
    };
  },
  methods: {
    ...mapActions("workers", {
      getWorker: GET_WORKER,
      getIntermediaryCompany: GET_INTERMEDIARY_COMPANY,
      deleteWorker: DELETE_WORKER,
      fetchApplications: GET_WORKER_APPLICATIONS
    }),
    handleUpdate() {
      this.$forceUpdate();
    },
    openDeleteWorkerDialog() {
      this.deleteWorkerDialog.dialog = true;
    },
    handleDeleteWorker() {
      this.isDeleting = true;
      this.deleteWorker({ id: this.$route.params.id })
        .then(() => {
          this.isDeleting = false;
          this.deleteWorkerDialog.dialog = false;
          this.$router.push("/workers");
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },
    handleTabChange(selectedTab) {
      const tabName = this.sections[selectedTab];
      this.$router.replace(`/workers/${this.currentWorkerId}/${tabName}`);
    },
    getColorByStatus
  }
};
</script>

<style lang="scss">
.worker {
  &--details {
    .header--dot-menu.dot-menu {
      position: absolute;
      right: 10px;
    }
  }
}
</style>
